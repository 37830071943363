import axios from "axios";

const config = {
  namespaced: true,
  state: {
    globalConfig: {},
    localConfig: {},
  },
  mutations: {
    SET_GLOBAL_CONFIG(state, data) {
      state.globalConfig = data;
    },
    SET_LOCAL_CONFIG(state, data) {
      state.localConfig = data;
    },
  },
  actions: {
    async loadConfig(context) {
      try {
        let globalConfig = await axios.get(`/config.global.json`);
        context.commit("SET_GLOBAL_CONFIG", eval(globalConfig.data))
      } catch (error) {
        // do nothing
      }

      try {
        let localConfig = await axios.get(`/config.local.json`);
        context.commit("SET_LOCAL_CONFIG", eval(localConfig.data));
      } catch (error) {
        // do nothing
      }
    }
  },
  getters: {
    configValue: (state) => (name) => {
      if (!(name in state.globalConfig)) {
        return false;
      }

      const value = state.localConfig[name];
      if (value === undefined) {
        const value = state.globalConfig[name];
        if (value === undefined) {
          return false;
        }

        return value;
      }

      return value;
    }
  }
};
export default config;
