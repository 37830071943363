import axios from "axios";
import EnvProvider from "@tt/vue-environment/EnvProvider";

const announceOrder = {
  namespaced: true,
  state: {
    search: "",
    loading: false,
    id: null,
    items: [],
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ORDER_ID(state, order) {
      state.id = order;
    },
    SET_ITEMS(state, data) {
      state.items = data;
    },
    DELETE_ITEM(state, itemId) {
      state.items = state.items.filter(item => item.id !== itemId);
      state.id = null;
    },
  },
  actions: {
    setOrder(context) {
      context.commit("SET_ORDER_ID", null);
      context.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url:
              EnvProvider.value("STORE_ORDER") +
              "order_shipments:quick-search?" +
              "storeId=" + window.sessionStorage.getItem("location") +
              "&pickupReference=" + context.state.search +
              "&exists[announcedDt]=false",
          headers: {
            Accept: "application/json",
            'x-entity-user': window.sessionStorage.getItem("credentials"),
            'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
          }
        })
          .then(response => {
            if (response.data.length > 0) {
              context.commit("SET_ORDER_ID", response.data[0].id);
            }
            resolve();
          })
          .catch(err => {
            console.log(err)
            reject(err["hydra:description"]);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      });
    },
    setAnnounceOrder(context) {
      return new Promise((resolve, reject) => {
        context.commit("SET_LOADING", true);
        axios({
          method: "put",
          url:
              EnvProvider.value("STORE_ORDER") +
              "order_shipments/" +
              context.state.id,
          data: {
            announcedDt: new Date()
          },
          headers: {
            Accept: "application/json",
            'x-entity-user': window.sessionStorage.getItem("credentials"),
            'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
          }
        })
          .then(() => {
            context.commit("DELETE_ITEM", context.state.id)
            resolve()
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      });
    },
    fetchItems(context, refresh = true) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);

        axios({
          method: "get",
          url:
              EnvProvider.value("STORE_ORDER") +
              "order_shipments:quick-search?" +
              "storeId=" + window.sessionStorage.getItem("location") +
              "&exists[sendDt]=true&exists[announcedDt]=false&order[sendDt]=asc&itemsPerPage=1000",
          headers: {
            Accept: "application/json",
            'x-entity-user': window.sessionStorage.getItem("credentials"),
            'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
          }
        })
        .then(response => {
          context.commit("SET_ITEMS", response.data);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          context.commit("SET_LOADING", false);
        });
      }
    }
  },
  getters: {
    totalToAnnounce: state => {
      return state.items.length;
    }
  }
};
export default announceOrder;
