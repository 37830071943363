<template>
  
</template>

<script>
export default {
    name: "InActivity",
    data(){
        return{
            timer: null
        }
    },
    methods:{
        startTimer(){
            //console.log('start timer');
            this.timer = setTimeout(() => this.handleInactivity(), 1740000); // is 29 min
        },
        resetTimer(){
            //console.log('reset timer');
            clearTimeout(this.timer);
            this.startTimer();
        },
        handleInactivity(){
            if(this.$parent.authenticated){
                this.$parent.authenticated = false;
                window.sessionStorage.removeItem("authenticated");
                window.sessionStorage.removeItem("credentials");
                window.sessionStorage.removeItem("fullname");
                window.sessionStorage.removeItem("location");
                window.sessionStorage.removeItem("locations");
            }
        }
    },
    mounted(){
        document.addEventListener("keypress", this.resetTimer, false);
        document.addEventListener("mousemove", this.resetTimer, false);
        document.addEventListener("mousedown", this.resetTimer, false);
        document.addEventListener("touchmove", this.resetTimer, false);
        document.addEventListener("touchstart", this.resetTimer, false);

        this.startTimer();

        let inActiveStartTime = null;
        let self = this;

        document.addEventListener('visibilitychange', function (event) {
            if (document.hidden) {
                inActiveStartTime = new Date();
            } else {
                let currentTime = new Date();
                if(inActiveStartTime !== null){
                    let diffTime = (currentTime - inActiveStartTime) / 1000;

                    if(diffTime >= 120){
                        self.handleInactivity();
                    }
                }
            }
        });
    }
}
</script>
