
import axios from "axios";

/*
    push urls naar ajaxcalls
    sla url op
    doe ajax call in een new object
    als succesvol dan verwijder url
    anders opnieuw
*/

let ajaxCalls = (function(){
    let settings = {
        queue:[],
        errors:[],
    };

    let init = function(myUrl, params, method, callback){
        return new ajax(myUrl, params, method, callback);
    };

    let queue = {
        add: function(myUrl, myParams){
            if(settings.queue.length > 0){
                if(settings.queue.indexOf(myUrl) === -1){
                    settings.queue.push(myUrl);
                }
            }else{
                settings.queue = [myUrl];
            }
        },
        remove: function(myUrl){
            settings.queue.forEach((item, index) => {
                if(item === myUrl){
                    settings.queue.splice(index, 1);
                }
            });
        }
    };

    return{
        init:init,
        settings: settings,
        queue:queue
    };
})();

const ajax = (function(myUrl, params, method, callback){
    let self = this;

    self.settings = {
        url: null,
        tryAgain: 0,
        delay: 0
    };

    self.init = function(myUrl, params, method, callback){
        self.settings.url = myUrl;
        self.settings.params = params || null;
        self.settings.method = method || 'get'
        self.settings.callback = callback || null;
        //self.makeCall();
    }

    self.makeCall = async function() {
        self.settings.url = myUrl;
        self.settings.params = params || null;
        self.settings.method = method || 'get'
        self.settings.callback = callback || null;

        ajaxCalls.queue.add(self.settings.url, self.settings.params);

        await self.delay(self.settings.delay);

        let call = axios({
            method: self.settings.method,
            url: self.settings.url,
            headers: {
                  'Accept': 'application/json',
                  'x-entity-user': window.sessionStorage.getItem("credentials"),
                  'x-entity-user-barcode': window.sessionStorage.getItem("barcode"),
            },
            data:self.settings.params
        });
        return call
        .then(result => {
            ajaxCalls.queue.remove(self.settings.url);
            return result;
         })
        .catch(error => {
            self.settings.tryAgain++;
            self.settings.delay = 10000;

            //ajaxCalls.queue.add(self.settings.url, self.settings.params);

            if(self.settings.tryAgain === 30){
                ajaxCalls.queue.remove(self.settings.url);
                ajaxCalls.settings.errors.push(error);
                return error;
            }else{
                //ajaxCalls.queue.remove(self.settings.url);
                return self.makeCall();
            }
        });
    };

    self.delay = function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    self.init(myUrl, params, method, callback);

    return self.makeCall();
});

export default ajaxCalls;
