import axios from "axios";
import EnvProvider from "@tt/vue-environment/EnvProvider";

const stock = {
  namespaced: true,
  modules: {},
  state: {
    loading: false,
    error: false,
    items: [],
    totalItems: 0,
    itemsPerPage: 999,
    itemsPage: 1,
    sortByField: "qty",
    sortDesc: true,
    search: ""
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
    SET_ITEMS(state, data) {
      state.items = data;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    setSearch(context, search) {
      context.commit("SET_SEARCH", search);
    },
    fetchItems(context) {
      context.commit("SET_ITEMS", []);
      const sku = context.rootState.products.sku;
      if (!sku) {
        return
      }

      let variantParams = sku.sizes.map((item) =>  { return "sku[]=" + item.sku })
      axios({
        method: "get",
        url:
            EnvProvider.value("STORE_INVENTORY") +
            "stocks:variants?" + variantParams.join("&") +
            "&order[" + context.state.sortByField + "]=" + (context.state.sortDesc ? "desc" : "asc")
        ,
        headers: {
          Accept: "application/json",
          'x-entity-user': window.sessionStorage.getItem("credentials") ?? EnvProvider.value("GUEST_USER")+":"+EnvProvider.value("GUEST_PASSWORD"),
          'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
        }
      }).then(
          (response) => {
            context.commit("SET_ITEMS", response.data);
          },
          err => {
            console.log(err);
          }
      );
    },

    searchItems(context) {
      context.dispatch("fetchItems", true);
      context.state.showItems = true;
    },
    resetOptions(context) {
      context.state.showItems = false;
    }
  },
  getters: {
    sortedStockLocations: (state) => {
    let items = state.items.sort((a, b) =>
      a.location.code.toLowerCase().localeCompare(b.location.code.toLowerCase())
    );

    if (window.sessionStorage.getItem('location') !== null) {
      items.forEach((item, i) => {
        if (item.location.code.toLowerCase() === window.sessionStorage.getItem('location').toLowerCase()) {
          items.splice(i, 1);
          items.unshift(item);
        }
      });
    }

    return items
    }
  }
};

export default stock;
