import axios from "axios";
import EnvProvider from "@tt/vue-environment/EnvProvider";

const pickUpOrder = {
  namespaced: true,
  state: {
    announcedOrders: [],
    items: [],
    loading: false,
    reference: "",
    fallback: false,
    foundOrder: null,
    foundOrderConfirmed: null,
    showError: false,
    showSuccess: false
  },
  mutations: {
    SET_REFERENCE(state, reference) {
      state.reference = reference;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_FALLBACK(state, fallback) {
      state.fallback = fallback;
    },
    SET_FOUND_ORDER(state, order) {
      state.foundOrder = order;
    },
    SET_FOUND_ORDER_CONFIRMED(state, order) {
      state.foundOrderConfirmed = order;
    },
    SET_SHOW_ERROR(state, showError) {
      state.showError = showError;
    },
    SET_SHOW_SUCCESS(state, showSuccess) {
      state.showSuccess = showSuccess;
    },
    SET_ITEMS(state, data) {
      state.items = data;
    },
    DELETE_ITEM(state, itemId) {
      state.items = state.items.filter(item => item.id !== itemId);
      state.id = null;
    },
  },
  actions: {
    fetchItems(context, refresh = true) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        axios({
          method: "get",
          url:
              EnvProvider.value("STORE_ORDER") +
              "order_shipments:quick-search?" +
              "storeId=" + window.sessionStorage.getItem("location") +
              "&exists[sendDt]=true&exists[announcedDt]=true&exists[deliveredDt]=false&order[announcedDt]=desc",
          headers: {
            Accept: "application/json",
            'x-entity-user': window.sessionStorage.getItem("credentials"),
            'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
          }
        }).then(response => {
              context.commit("SET_ITEMS", response.data);
        }).catch(err => {
          console.log(err);
        }).finally(()=> {
          context.commit("SET_LOADING", false);
        });
      }
    },
    getOrderByControlNumber(context, controlNumber) {
      context.commit("SET_LOADING", true);
      context.commit("SET_SHOW_ERROR", false);
      context.commit("SET_SHOW_SUCCESS", false);
      axios({
        method: "get",
        url:
            EnvProvider.value("STORE_ORDER") +
            "order_shipments:quick-search?" +
            "storeId=" + window.sessionStorage.getItem("location") +
            "&controlNumber=" + controlNumber +
            "&exists[deliveredDt]=false&exists[announcedDt]=true",
        headers: {
          Accept: "application/json",
          'x-entity-user': window.sessionStorage.getItem("credentials"),
          'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
        }
      }).then(response => {
        if (response.data.length > 0) {
          context.commit("SET_FOUND_ORDER", response.data[0]);
          context.commit("SET_SHOW_ERROR", false);
        } else {
          context.commit("SET_SHOW_ERROR", true);
        }
      }).catch(err => {
        console.log(err);
      }).finally(()=> {
        context.commit("SET_LOADING", false);
      });
    },
    getOrderByAddress(context, address) {
      context.commit("SET_LOADING", true);
      context.commit("SET_SHOW_ERROR", false);
      context.commit("SET_SHOW_SUCCESS", false);
      axios({
        method: "get",
        url:
            EnvProvider.value("STORE_ORDER") +
            "order_shipments:quick-search?" +
            "storeId=" + window.sessionStorage.getItem("location") +
            "&order.invoiceAddress.zipcode=" +
            address.zipcode +
            "&order.invoiceAddress.houseNumber=" +
            address.houseNumber +
            "&exists[announcedDt]=true&exists[deliveredDt]=false",
        headers: {
          Accept: "application/json",
          'x-entity-user': window.sessionStorage.getItem("credentials"),
          'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
        }
      }).then(response => {
        if (response.data.length > 0) {
          context.commit("SET_FOUND_ORDER", response.data[0]);
          context.commit("SET_SHOW_ERROR", false);
        } else {
          context.commit("SET_SHOW_ERROR", true);
        }
      }).catch(err => {
        console.log(err);
      }).finally(()=> {
        context.commit("SET_LOADING", false);
      });
    },
    getOrderByReference(context) {
      context.commit("SET_LOADING", true);
      context.commit("SET_SHOW_ERROR", false);
      context.commit("SET_SHOW_SUCCESS", false);
      axios({
        method: "get",
        url:
          EnvProvider.value("STORE_ORDER") +
          "order_shipments:quick-search?" +
          "storeId=" + window.sessionStorage.getItem("location") +
          "&pickupReference=" +
          context.state.reference +
          "&exists[deliveredDt]=false&exists[announcedDt]=true",
        headers: {
          Accept: "application/json",
          'x-entity-user': window.sessionStorage.getItem("credentials"),
          'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
        }
      }).then(response => {
        if (response.data.length > 0) {
          context.commit("SET_SHOW_ERROR", false);
          context.commit("SET_FOUND_ORDER_CONFIRMED", response.data[0]);
          context.dispatch("setOrderDelivered")
        } else {
          context.commit("SET_SHOW_ERROR", true);
          context.commit("SET_REFERENCE", "");
        }
      }).catch(err => {
        console.log(err);
      }).finally(()=> {
        context.commit("SET_LOADING", false);
      });
    },
    setOrderDelivered(context) {
      if (context.state.foundOrder.id !== context.state.foundOrderConfirmed.id) {
        context.commit("SET_SHOW_ERROR", true);
        return;
      }

      const datetime = new Date();
      context.commit("SET_LOADING", true);
      axios({
        method: "put",
        url:
          EnvProvider.value("STORE_ORDER") +
          "order_shipments/" +
          context.state.foundOrder.id,
        data: {
          deliveredDt: datetime
        },
        headers: {
          Accept: "application/json",
          'x-entity-user': window.sessionStorage.getItem("credentials"),
          'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
        }
      }).then(() => {
        context.dispatch("reset", true);
        setTimeout(() => {
          context.commit("SET_FOUND_ORDER", null)
          context.commit("SET_FOUND_ORDER_CONFIRMED", null)
          context.commit("SET_SHOW_SUCCESS", false)
        }, 3500);
        context.commit("DELETE_ITEM", context.state.foundOrderConfirmed.id)
      }).catch(err => {
        console.log(err);
      }).finally(()=> {
        context.commit("SET_LOADING", false);
      });
    },
    resetError(context) {
      context.commit("SET_SHOW_ERROR", false);
    },
    reset(context, success = false) {
      context.commit("SET_SHOW_SUCCESS", success);
      context.commit("SET_SHOW_ERROR", false);
      context.commit("SET_FALLBACK", false);
      context.commit("SET_FOUND_ORDER", null);
      context.commit("SET_REFERENCE", '');
    }
  },
  getters: {
    totalAnnounced: state => {
      return state.items.length;
    }
  }
};
export default pickUpOrder;
