var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"development"},[_c('div',{staticClass:"heading"},[_vm._v("Diagnostics")]),_c('dl',[_c('dt',[_vm._v("Version")]),_c('dd',{staticClass:"diag-version"},[_vm._v(" "+_vm._s(this.$parent.version)+" ")])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("Internet connection")]),_c('dd',{staticClass:"diag-internet"},[_vm._v("No internet")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("User")]),_c('dd',{staticClass:"diag-user"},[_c('pre')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("credentials")]),_c('dd',{staticClass:"diag-credentials"},[_c('pre')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("location")]),_c('dd',{staticClass:"diag-location"},[_c('pre')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("location name")]),_c('dd',{staticClass:"diag-location-name"},[_c('pre')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("API Queue")]),_c('dd',{staticClass:"diag-queue"},[_c('pre',[_vm._v("\n      ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("API error")]),_c('dd',{staticClass:"diag-api-error"},[_c('pre',[_vm._v("\n      ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("locations")]),_c('dd',{staticClass:"diag-locations"},[_c('pre')])])
}]

export { render, staticRenderFns }