<template>
  <div class="development">
    <div class="heading">Diagnostics</div>

    <dl>
      <dt>Version</dt>
      <dd class="diag-version">
        {{ this.$parent.version }}
      </dd>
    </dl>

    <dl>
      <dt>Internet connection</dt>
      <dd class="diag-internet">No internet</dd>
    </dl>

    <dl>
      <dt>User</dt>
      <dd class="diag-user">
        <pre></pre>
      </dd>

    </dl>

    <dl>
      <dt>credentials</dt>
      <dd class="diag-credentials">
        <pre></pre>
      </dd>
    </dl>

    <dl>
      <dt>location</dt>
      <dd class="diag-location">
          <pre></pre>
      </dd>
    </dl>

    <dl>
      <dt>location name</dt>
      <dd class="diag-location-name">
          <pre></pre>
      </dd>
    </dl>

    <dl>
      <dt>API Queue</dt>
      <dd class="diag-queue">
        <pre>

        </pre>
      </dd>
    </dl>

    <dl>
      <dt>API error</dt>
      <dd class="diag-api-error">
        <pre>

        </pre>
      </dd>
    </dl>

    <dl>
      <dt>locations</dt>
      <dd class="diag-locations">
          <pre></pre>
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: "Development",
  mounted(){
    setInterval(() =>
      this.writeStatus('.diag-internet', (window.navigator.onLine) ? 'online' : 'offline')
    ,1000);

    setInterval(() => {
      this.writeStatus('.diag-user pre', window.sessionStorage.getItem("fullname"));
      this.writeStatus('.diag-credentials pre', window.sessionStorage.getItem("credentials"));
      this.writeStatus('.diag-locations pre', this.preJSON(window.sessionStorage.getItem("locations")));
      this.writeStatus('.diag-location pre', window.sessionStorage.getItem("location"));
      this.writeStatus('.diag-location-name pre', window.sessionStorage.getItem("location-name"));
    } ,3000);


    setInterval(() => {
      //console.log(this.$ajaxCalls.settings.queue);
      this.writeStatus('.diag-queue pre', this.preJSON(JSON.stringify(this.$ajaxCalls.settings.queue)));
      this.writeStatus('.diag-api-error pre', this.preJSON(JSON.stringify(this.$ajaxCalls.settings.errors)));
    }, 10);

  },
  methods:{
    writeStatus: function(elm, message){
      document.querySelector(elm).innerText = message;
    },
    preJSON: function(data){
      return JSON.stringify(JSON.parse(data),undefined,2);
    }
  }
};
</script>

